import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { useUser } from 'context/UserContext';
import env from 'utils/env';

import Button from 'components/design-system/Button';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import DynamicTabGrey from 'components/shared/Tabs/DynamicTabGrey';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import Modal from 'components/shared/modal/Modal';

const clipAPIHost = (apiHost) =>
  apiHost?.replace(/(https:\/\/|.performance.co.id)/gi, '');

const BlueGear = ({ buildSettingList }) => {
  const lsEnv = JSON.parse(localStorage.getItem('env') || '{}');
  const redLsEnv = JSON.parse(localStorage.getItem('redLsEnv') || '{}');
  const personalToken = env?.SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

  const [showModal, setShowModal] = useState(false);
  const { config, user } = useUser() || {};
  const [setting, setSetting] = useState({
    PERFORMANCE_API_HOST: clipAPIHost(
      redLsEnv?.PERFORMANCE_API_HOST ||
        lsEnv?.PERFORMANCE_API_HOST ||
        env.PERFORMANCE_API_HOST
    ),
    AUTHORIZATION_METHOD:
      redLsEnv?.AUTHORIZATION_METHOD ||
      lsEnv?.AUTHORIZATION_METHOD ||
      env.AUTHORIZATION_METHOD,
    USE_MOCK: lsEnv?.USE_MOCK,
    IS_BCA: lsEnv?.IS_BCA
  });
  const [listOpenPullRequests, setListOpenPullRequests] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState({});

  const settingList = buildSettingList({
    state: setting,
    setState: setSetting,
    config,
    user,
    redLsEnv
  });

  const handleSave = async () => {
    const { ...newEnv } = setting;
    newEnv.PERFORMANCE_API_HOST =
      'https://' + setting.PERFORMANCE_API_HOST + '.performance.co.id';
    localStorage.setItem('env', JSON.stringify(newEnv));

    if (selectedBranch?.branchName) {
      localStorage.setItem('selectedBranch', selectedBranch?.branchName);
      return (window.location.href =
        'https://' +
        selectedBranch?.branchName.replace(/\//g, '-') +
        '--performance-development.netlify.app');
    }

    window.location.href = '/';
  };

  const getLink = (str) => {
    return str?.match(/\[Review(.*?)\)/gi);
  };

  const handleClickIcon = () => {
    setCurrBranch();
    setShowModal(true);
  };

  const handleClickLink = (link) => {
    window.open(link?.replace(/\[Review]\(|\)/gi, ''), '_blank');
  };

  const handleBackToStaging = () => {
    localStorage.removeItem('selectedBranch');
    window.location.href = 'https://performance-development.netlify.app';
  };

  const setCurrBranch = () => {
    const lsBranch = localStorage.getItem('selectedBranch');
    const selBranch = listOpenPullRequests?.filter(
      (data) => data?.branchName === lsBranch
    );
    if (lsBranch && selBranch.length > 0) {
      setSelectedBranch({
        prTitle: selBranch?.[0]?.prTitle,
        branchName: selBranch?.[0]?.branchName,
        linkReview: getLink(selBranch?.[0]?.description)
      });
    } else {
      setSelectedBranch({
        prTitle: 'staging'
      });
    }
  };

  const getPullRequests = async () => {
    const headers = {
      Authorization: `token ${personalToken}`
    };
    let openPullRequestsData = [];
    const { data } = await axios.get(
      'https://api.github.com/repos/Happy-5/Performance-WebApp/pulls?state=open',
      {
        headers: {
          ...headers
        }
      }
    );
    data &&
      data?.map((d) => {
        openPullRequestsData.push({
          id: d?.id,
          prTitle: d?.title,
          branchName: d?.head?.ref,
          description: d?.body
        });
      });
    setListOpenPullRequests(openPullRequestsData);
  };

  useEffect(() => {
    if (personalToken) {
      getPullRequests();
    }
  }, []);

  return (
    <>
      <SVGIcon
        size="48"
        iconName="icon-settings"
        fillColor="var(--b-700)"
        onClick={() => handleClickIcon()}
      />
      {showModal && (
        <Modal
          useBorder
          withFooter
          className="max-w-[400px]"
          title="Environment Configuration"
          eventOnClickClose={() => setShowModal(false)}
          contentClass="overflow-auto grid gap-[16px]"
          withCloseIcon={false}
          withPrimaryBtn={{
            onClick: handleSave,
            title: 'Save',
            disabled: redLsEnv?.USE_PRODUCTION_ENV
          }}
          maxHeight={500}
        >
          {selectedBranch?.branchName && (
            <Button.Tertiary
              onClick={handleBackToStaging}
              disabled={redLsEnv?.USE_PRODUCTION_ENV}
            >
              Back to staging
            </Button.Tertiary>
          )}
          {personalToken && selectedBranch?.linkReview?.length > 0 && (
            <div className="h-[36px] overflow-x-auto whitespace-whitespace-nowrap flex">
              {selectedBranch?.linkReview?.map((link, index) => (
                <Button.Tertiary
                  key={index}
                  onClick={() => handleClickLink(link)}
                >
                  Link Review {index + 1}
                </Button.Tertiary>
              ))}
            </div>
          )}
          {settingList.map((setting, index) => {
            return (
              <>
                {setting.type === 'tabs' && setting.config === 'blueGear' && (
                  <div key={index}>
                    <p className="typography-h100 typography-secondary mb-[8px]">
                      {setting.title}:
                    </p>
                    <DynamicTabGrey {...setting} />
                    {redLsEnv?.USE_PRODUCTION_ENV ? (
                      <p className="typography-h100 text-n-600 mt-[8px]">
                        You need to deactivate setting in red gear to use this
                        configuration
                      </p>
                    ) : (
                      setting.disabled && (
                        <p className="typography-h100 text-n-600 mt-[8px]">
                          You need to be logged in as someone with admin role
                        </p>
                      )
                    )}
                  </div>
                )}
                {setting.type === 'text' && !setting.disabled && (
                  <InputField {...setting} className="mt-0" />
                )}
                {setting.type === 'toggle' &&
                  setting.config === 'blueGear' &&
                  (!setting.localOnly ||
                    (setting.localOnly &&
                      process.env.NODE_ENV === 'development')) && (
                    <div className="flex justify-between" key={index}>
                      <span className="typography-h200">{setting.title}</span>
                      <SmallToggleSwitchPurple {...setting} />
                    </div>
                  )}
              </>
            );
          })}
        </Modal>
      )}
    </>
  );
};

export default BlueGear;

import React from 'react';

import { phaseName } from 'utils/FormalReview';
import { getMessageDepedencyNotification } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import VerifySection from './VerifySection';

const ReviewPhaseDependencyVerify = ({ cycleData }) => {
  const buildPhaseString = (dependenciesArray) => {
    let phaseNameArray = dependenciesArray?.map((dependency) =>
      phaseName(dependency)
    );
    let lastPhase = phaseNameArray.pop();
    let phaseString =
      phaseNameArray.join(', ') +
      (phaseNameArray.length > 0 ? ' and ' : '') +
      lastPhase;

    return phaseString;
  };

  const reviewPhaseDependencyContent = (phase) => {
    const phaseData = cycleData[phase];
    const dependenciesArray = phaseData?.dependencies;

    if (phaseData && (!dependenciesArray || dependenciesArray?.length === 0)) {
      return (
        <p className="typography-paragraph typography-tertiary">
          No dependency
        </p>
      );
    } else if (dependenciesArray?.length > 0) {
      return (
        <div>
          <p className="typography-paragraph">
            {buildPhaseString(dependenciesArray)}
          </p>
          <p className="typography-h100 typography-secondary">
            {getMessageDepedencyNotification(phaseData)}
          </p>
        </div>
      );
    }

    return (
      <p className="typography-paragraph typography-tertiary">
        Not an active phase
      </p>
    );
  };

  let reviewPhaseDependencyArray = [
    {
      name: getObjectiveLocale('Self Review'),
      content: reviewPhaseDependencyContent('selfReview')
    },
    {
      name: getObjectiveLocale('Upward Review'),
      content: reviewPhaseDependencyContent('upwardReview')
    },
    {
      name: getObjectiveLocale('Peer Review'),
      content: reviewPhaseDependencyContent('peerReview')
    },
    {
      name: getObjectiveLocale('Manager Review'),
      content: reviewPhaseDependencyContent('managerReview')
    },
    {
      name: getObjectiveLocale('Indirect Manager Review'),
      content: reviewPhaseDependencyContent('indirectManagerReview')
    }
  ];
  return (
    <VerifySection
      title="Review phase dependency"
      dataArray={reviewPhaseDependencyArray}
      className="py-[40px]"
    />
  );
};

export default ReviewPhaseDependencyVerify;

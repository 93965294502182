import React from 'react';

import dayjs from 'dayjs';

import { getObjectiveLocale } from 'utils/HelperUtils';

import VerifySection from './VerifySection';

const ReviewScheduleVerify = ({ cycleData }) => {
  let startReviewProcessPeriod = dayjs(cycleData.reviewProcessStartsAt).format(
    'D MMMM YYYY'
  );
  let endReviewProcessPeriod = dayjs(cycleData.reviewProcessEndsAt).format(
    'D MMMM YYYY'
  );
  let nextStartProcessRecurringDate = dayjs(cycleData.reviewProcessStartsAt)
    .add(cycleData.recurrence?.interval, 'months')
    .format('D MMMM YYYY');
  let nextEndProcessRecurringDate = dayjs(cycleData.reviewProcessEndsAt)
    .add(cycleData.recurrence?.interval, 'months')
    .format('D MMMM YYYY');

  const reviewScheduleContent = (phase) => {
    const phaseData = cycleData[phase];
    const startSchedule = phaseData?.startsAt
      ? phaseData?.startsAt
      : phaseData?.startedAt;

    const endSchedule = phaseData?.endsAt
      ? phaseData?.endsAt
      : phaseData?.endedAt;
    let startsAtbyPhase = startSchedule ? (
      dayjs(startSchedule).format('D MMMM YYYY')
    ) : (
      <span className="typography-paragraph typography-tertiary">
        {getObjectiveLocale('Start date is not yet scheduled')}
      </span>
    );
    let endsAtbyPhase = endSchedule ? (
      dayjs(endSchedule).format('D MMMM YYYY')
    ) : (
      <span className="typography-paragraph typography-tertiary">
        {getObjectiveLocale('End date is not yet scheduled')}
      </span>
    );
    if (!startSchedule && !endSchedule) {
      return (
        <p className="typography-paragraph typography-tertiary">
          {getObjectiveLocale('No schedule yet')}
        </p>
      );
    }
    return (
      <p className="typography-paragraph">
        {startsAtbyPhase} - {endsAtbyPhase}
      </p>
    );
  };

  let reviewScheduleArray = [
    {
      name: 'Cycle',
      listClassName: 'h-[56px]',
      content:
        !cycleData.reviewProcessStartsAt || !cycleData.reviewProcessEndsAt ? (
          <p className="typography-paragraph typography-tertiary">
            {getObjectiveLocale('No schedule selected')}
          </p>
        ) : (
          <div>
            <p className="typography-paragraph">
              {startReviewProcessPeriod} - {endReviewProcessPeriod},{' '}
              {cycleData?.recurrence && (
                <span className="typography-h400">
                  {getObjectiveLocale('repeating every')}{' '}
                  {cycleData?.recurrence?.interval}{' '}
                  {getObjectiveLocale('months')}
                </span>
              )}
            </p>
            {(nextStartProcessRecurringDate || nextEndProcessRecurringDate) &&
              cycleData.recurrence?.interval && (
                <p className="typography-h100 typography-secondary">
                  {getObjectiveLocale(
                    'Next scoring period to be included will be on'
                  )}{' '}
                  {nextStartProcessRecurringDate} -{' '}
                  {nextEndProcessRecurringDate}
                </p>
              )}
          </div>
        )
    },
    {
      name: getObjectiveLocale('Self Review'),
      content: reviewScheduleContent('selfReview')
    },
    {
      name: getObjectiveLocale('Upward Review'),
      content: reviewScheduleContent('upwardReview')
    },
    {
      name: getObjectiveLocale('Peer Review'),
      content: reviewScheduleContent('peerReview')
    },
    {
      name: getObjectiveLocale('Manager Review'),
      content: reviewScheduleContent('managerReview')
    },
    {
      name: getObjectiveLocale('Indirect Manager Review'),
      content: reviewScheduleContent('indirectManagerReview')
    }
  ];
  return (
    <VerifySection
      title="Review schedule"
      dataArray={reviewScheduleArray}
      className="py-[40px]"
    />
  );
};

export default ReviewScheduleVerify;
